import { ReactElement } from 'react';
import classNames from 'classnames';

import { BreadCrumbItem, NavigationItem as NavigationItemType } from '../../../shared/types';
// eslint-disable-next-line import/no-cycle
import { NavigationItem } from '../NavigationItem/NavigationItem';

const trackingLabel = (navTrackingLabel: string, parentNavTrackingLabel?: string): string => {
    return parentNavTrackingLabel
        ? `${parentNavTrackingLabel}_${navTrackingLabel}`
        : navTrackingLabel;
};

type NavigationGroupProps = {
    items: NavigationItemType[];
    itemLevel: number;
    navigationLevel: number;
    breadcrumb?: BreadCrumbItem[];
    parentTrackingLabel?: string;
    selectedItemHasChildren: boolean;
    className?: string;
};

const NavigationGroup = ({
    items,
    itemLevel,
    navigationLevel,
    breadcrumb = [],
    parentTrackingLabel,
    selectedItemHasChildren,
    className,
}: NavigationGroupProps): ReactElement => (
    <ul className={classNames('pl-0 pt-0-5x', className)} data-level={itemLevel}>
        {items?.map(item => (
            <NavigationItem
                key={item.id}
                item={item}
                navigationLevel={navigationLevel}
                itemLevel={itemLevel}
                breadcrumb={breadcrumb}
                trackingLabel={trackingLabel(item.title, parentTrackingLabel)}
                selectedItemHasChildren={selectedItemHasChildren}
            />
        ))}
    </ul>
);

export { NavigationGroup };
