import { trackEecPromotionClick, trackEecPromotionImpression } from '@jsmdg/tracking';
import { Banner, ResponsiveFrame, useImpressionCallback } from '@jsmdg/yoshi';
import { Ref, useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import classNames from 'classnames';

import { Creative } from '../../../shared/types';
import styles from './ACMBanner.module.scss';
import { CreativeName, PromotionType } from '../../enums/trackingPromotions';

const messages = defineMessages({
    hoursText: { defaultMessage: 'Stunden' },
    daysText: { defaultMessage: 'Tage' },
    minutesText: { defaultMessage: 'Minuten' },
    secondsText: { defaultMessage: 'Sekunden' },
});

export const ACMBanner = ({
    image,
    url,
    trackingName,
    campaignName,
    placeholderId,
    countdown,
}: Creative): JSX.Element => {
    const intl = useIntl();

    const name = campaignName;
    const id = trackingName;
    const position = 'vertical1_horizontal1';

    const data = {
        promotionTrackingPayload: {
            id,
            name,
            creative: `${placeholderId}_CategoryLanding`,
            position,
        },
        promotionTrackingEventData: {
            creativeName: CreativeName.NavigationBanner,
            creativeSlot: position,
            promotionName: name,
            promotionId: id,
            locationId: url || '',
            promotionCountdown: countdown ? 'true' : 'false',
            promotionPlaceholder: placeholderId,
            promotionType: PromotionType.Dynamic,
        },
    };

    const trackProductImpression = useCallback(() => {
        trackEecPromotionImpression(
            [data.promotionTrackingPayload],

            data.promotionTrackingEventData,
        );
    }, [data.promotionTrackingEventData, data.promotionTrackingPayload]);

    const setImpressionTrackedElement = useImpressionCallback(trackProductImpression);

    return (
        <ResponsiveFrame
            width={250}
            height={120}
            className={classNames('mb-2x', styles.bannerLink)}
        >
            <Banner
                skeletonClasses={styles.skeleton}
                imageUrl={image.url}
                alt={image.alt}
                link={url}
                lazyLoad // so that mobile browsers don't load this.
                className="position-absolute"
                fullWidth
                msCountdown={
                    countdown
                        ? new Date(countdown.endDateUtc).getTime() - new Date().getTime()
                        : undefined
                }
                countdownUnits={{
                    days: intl.formatMessage(messages.daysText),
                    hours: intl.formatMessage(messages.hoursText),
                    minutes: intl.formatMessage(messages.minutesText),
                    seconds: intl.formatMessage(messages.secondsText),
                }}
                textColor={countdown?.color as string}
                ref={setImpressionTrackedElement as Ref<HTMLDivElement>}
                onClick={() => {
                    trackEecPromotionClick(
                        data.promotionTrackingPayload,
                        data.promotionTrackingEventData,
                    );
                }}
            />
        </ResponsiveFrame>
    );
};
