import { createFragment } from '@jsmdg/react-fragment-scripts/fragment';

import { Navigation } from './components/Navigation/Navigation';
import { RootComponentProps } from '../shared/types';
import { FragmentContext } from './types/fragmentContext';

const { Fragment, init } = createFragment<RootComponentProps, FragmentContext>(
    Navigation,
    ({ rootComponentProps }) => ({ ...rootComponentProps }),
);

export { Fragment, init };
