import classNames from 'classnames';
import {
    ClickElement,
    GA4EventName,
    GA4FeatureCategory,
    trackAnalyticsEvent,
} from '@jsmdg/tracking';
import { MouseEvent } from 'react';
import { useFragmentContext } from '@jsmdg/react-fragment-scripts/fragment';
import { Link, LinkVariant } from '@jsmdg/yoshi';

// eslint-disable-next-line import/no-cycle
import { NavigationGroup } from '../NavigationGroup/NavigationGroup';
import styles from './NavigationItem.module.scss';
import { BreadCrumbItem, NavigationItem as NavigationItemInterface } from '../../../shared/types';
import { FragmentContext } from '../../types/fragmentContext';

type NavigationItemProps = {
    item: NavigationItemInterface;
    itemLevel: number;
    navigationLevel: number;
    breadcrumb?: BreadCrumbItem[];
    trackingLabel?: string;
    selectedItemHasChildren: boolean;
    className?: string;
};

const NavigationItem = ({
    item,
    itemLevel,
    navigationLevel,
    breadcrumb = [],
    trackingLabel,
    selectedItemHasChildren,
    className,
}: NavigationItemProps): JSX.Element | null => {
    const { tenantConfig, isMydays } = useFragmentContext<FragmentContext>();

    const shouldRender = item.id !== tenantConfig.glossarPageId;

    if (!shouldRender) {
        return null;
    }

    const topBreadCrumb = breadcrumb[0];

    const isNodeInBreadCrumb = topBreadCrumb && topBreadCrumb.id === item.id;
    const isLastBreadcrumb = isNodeInBreadCrumb && breadcrumb.length === 1;
    const itemHasChildren = item.children && item.children.length > 0;
    const levelsToShow = isMydays ? 3 : 2;

    // Example level 1: "Erlebnisse mit Tieren"
    // Example level 2: "Alpaka Wanderungen"
    // Always highlight matches on level 2, but also on level 1 if we are on that page
    const shouldSetActive = isNodeInBreadCrumb && (isLastBreadcrumb || itemLevel > 1);
    const shouldExpand = isNodeInBreadCrumb && itemHasChildren && itemLevel < levelsToShow;

    const onLinkClick = (event: MouseEvent): void => {
        event.preventDefault();

        trackAnalyticsEvent({
            category: 'SideNavi',
            action: 'Click',
            label: trackingLabel,
            eventData: {
                eventName: GA4EventName.ClickNavigationItem,
                feature_category: GA4FeatureCategory.SideNavigation,
                click_element: `${ClickElement.ExperienceCategories} > ${
                    trackingLabel?.replace('_', ' > ') ?? ''
                }`,
                click_text: item.title,
                click_url: window.location.origin + item.uri,
                navigationlevel: itemLevel - 1,
            },
        });

        const currentUrlSearchParams = new URLSearchParams(window.location.search);
        const location: Record<string, string | null> = {
            lat: currentUrlSearchParams.get('lat'),
            long: currentUrlSearchParams.get('long'),
            locationName: currentUrlSearchParams.get('locationName'),
            distance: currentUrlSearchParams.get('distance'),
        };

        window.location.href =
            !tenantConfig.giftBoxPages.some(page => item.uri.includes(page)) &&
            Object.values(location).every((value: string | null) => value)
                ? `${item.uri}?${new URLSearchParams(
                      location as Record<string, string>,
                  ).toString()}`
                : item.uri;
    };

    const isAllExperiencesEntry = item.id === tenantConfig.allExperiencesPageId;

    const shouldHighlightItem =
        itemLevel > 1 &&
        (selectedItemHasChildren ? itemLevel > navigationLevel : itemLevel === navigationLevel);

    const isActive = shouldSetActive && itemLevel === navigationLevel;

    return (
        <li
            className={classNames('pt-0-5x', className, styles.navigationItem, {
                [styles.active]: shouldSetActive,
                [styles.allExperiences]: isAllExperiencesEntry,
                'pb-0-5x': !shouldExpand,
                'pt-0 pb-1x mb-0-5x w-100': isAllExperiencesEntry,
                'fw-bold':
                    (shouldExpand &&
                        itemLevel === navigationLevel - 1 &&
                        !selectedItemHasChildren) ||
                    shouldHighlightItem,
            })}
        >
            <Link
                href={item.uri}
                onClick={onLinkClick}
                variant={isActive ? LinkVariant.BrandQuiet : LinkVariant.GreyQuiet}
                className={classNames(isAllExperiencesEntry ? 'd-inline-block' : '', {
                    'fw-bold': isActive,
                })}
            >
                <div
                    data-level={itemLevel}
                    className={classNames('d-flex justify-content-between align-items-center', {
                        'pl-3x': itemLevel === 2,
                        'pl-6x': itemLevel === 3,
                    })}
                >
                    {item.title}
                </div>
            </Link>
            {shouldExpand && (
                <NavigationGroup
                    className={classNames({ 'd-block': shouldSetActive })}
                    items={item.children}
                    navigationLevel={navigationLevel}
                    itemLevel={itemLevel + 1}
                    breadcrumb={breadcrumb.slice(1)} // remove current node in breadcrumb
                    parentTrackingLabel={trackingLabel}
                    selectedItemHasChildren={selectedItemHasChildren}
                />
            )}
        </li>
    );
};

export { NavigationItem };
